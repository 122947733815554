import './RecognitionInput.css';
import React, {useEffect, useRef, useState} from 'react'
import SpeechRecognition, {useSpeechRecognition} from 'react-speech-recognition'
import {Icon, Popup, TextArea} from "semantic-ui-react";

const RecognitionInput = ({value, onChange, onChangePosition, placeholder, onKeyDown}) => {
    let input = useRef();
    let [startPosition, setStartPosition] = useState(0);
    let [endPosition, setEndPosition] = useState(0);
    useEffect(()=>{
        const interval = setInterval(() => {
            if(input.current !== undefined && (startPosition !== input.current.selectionStart || endPosition !== input.current.selectionEnd) && input.current === document.activeElement){
                setStartPosition(input.current.selectionStart);
                setEndPosition(input.current.selectionEnd);
                if(onChangePosition !== undefined)
                    onChangePosition({start: input.current.selectionStart, end: input.current.selectionEnd});
            }
        }, 1000);
        return () => clearInterval(interval);
    }, []);
    
    const { transcript, resetTranscript } = useSpeechRecognition();
    let [isRec, setIsRec] = useState(false)
    
    if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
        alert('browserSupportsSpeechRecognition')
        return null
    }

    let capitalizeFirstLetter = (string) => {
        if(string == undefined || string == null) return '';
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    let popup = <Popup
        on='click'
        //pinned
        open={isRec}
        position='top center'
        content={<div className={'microphone-popup-content'}>
            {((transcript === undefined || transcript === null || transcript === '') ? <span style={{opacity: '0.5'}}>Говорите чётко и громко</span> : '')}{capitalizeFirstLetter(transcript)}
            <div className={'microphone-popup-content-buttons'}>
                <Icon
                    size='large'
                    disabled={(transcript === undefined || transcript === null || transcript === '')}
                    onClick={() => {
                        SpeechRecognition.stopListening();
                        let before = value.substring(0, startPosition);
                        let after = value.substring(endPosition);
                        onChange({target: {value: before + capitalizeFirstLetter(transcript) + after}, text: before + capitalizeFirstLetter(transcript) + after});
                        setIsRec(false);
                    }}
                    color={"green"}
                    name={"check"}/>

                <Icon
                    size='large'
                    onClick={() => {
                        SpeechRecognition.stopListening();
                        SpeechRecognition.startListening();
                    }}
                    color={"black"}
                    name={"refresh"}/>

                <Icon
                    size='large'
                    onClick={() => {
                        SpeechRecognition.stopListening();
                        setIsRec(false);
                    }}
                    color={"red"}
                    name={"close"}/>
            </div>
        </div>}
        trigger={<Icon
            onClick={() => {
                if(isRec)
                    SpeechRecognition.stopListening();
                else
                    SpeechRecognition.startListening();

                setIsRec(!isRec);
            }}
            className={'microphone-in-input'}
            color={isRec ? "red" : "grey"}
            name={isRec ? "circle thin" : "microphone"}/>} />;

    return (<div style={{position: 'relative'}}><TextArea
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onKeyDown={onKeyDown}
        />
            {popup}</div>
    )
}
export default RecognitionInput