import React from "react";
import ReactMarkdown from 'react-markdown'

export function getElementsFromFormattedText(text) {
    return <ReactMarkdown linkTarget={'_blank'} className={'markdown-display-container'}>{text}</ReactMarkdown>;
}

export function uuidv4() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

export function get20Symbols(name) {
    if(name.length <= 20)
        return name;
    let extra = Math.round((name.length - 20) + 3);
    let newName = '';
    let firstPartLength = Math.round(name.length/2 - extra/2);
    newName = name.substr(0, firstPartLength);
    newName += '...';
    //debugger
    newName += name.substr(firstPartLength + extra, Math.round(name.length - (firstPartLength + extra)));
    return newName;
}