import './FullScreenChat.css';
import {Button, Container, Form, Icon, Loader} from "semantic-ui-react";
import {useEffect, useRef, useState} from "react";
import api from "../../utils/api";
import moment from "moment";
import {get20Symbols, getElementsFromFormattedText} from "../../utils/textHelper";
import RecognitionInput from "../../components/RecognitionInput/RecognitionInput";
import {useWindowSize} from "../../utils/windowSize";
import * as PropTypes from "prop-types";
import createConnection from "../../utils/webSocket";


function Typewriter({text, delay, enable}) {
    const [currentText, setCurrentText] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);
    
    useEffect(() => {
        if (currentIndex < text.split(' ').length) {
            const timeout = setTimeout(() => {
                setCurrentText(prevText => prevText + ' ' + text.split(' ')[currentIndex]);
                setCurrentIndex(prevIndex => {
                    return prevIndex + 1
                });
            }, delay);

            return () => clearTimeout(timeout);
        }
    }, [currentIndex, delay, text]);
    if(!enable)
        return getElementsFromFormattedText(text)
    return getElementsFromFormattedText(currentText)
}

Typewriter.propTypes = {delay: PropTypes.number};

function FullScreenChat({serverUrl, token, globalFilterValue, userId, aiServerIsAvailable}) {
    const [commandsLog, setCommandsLog] = useState([])
    const [width, height] = useWindowSize();
    const [isLoadingLogs, setIsLoadingLogs] = useState(false)
    const [command, setCommand] = useState('')
    let [totalCommandsLogLenght, setTotalCommandsLogLenght] = useState(0);
    let [file, setFile] = useState(null);
    let fileInputRef = useRef();
    let scrollToBottom = () => {
        setTimeout(()=>{
            let chatBody = document.getElementsByClassName('wrapper-for-chat-body')[0];
            if(chatBody)
                chatBody.scrollTop = chatBody.scrollHeight + 1000;
        }, 200);
    }
    const sendCommand = () => {
        if(commandsLog.filter(x=>x.isLoading).length > 0)
            return;
        let temp = commandsLog.map(x=>x);
        temp.push({
            time: moment(new Date()).format('HH:mm'),
            fromUser: true,
            command: command,
            files: JSON.stringify([file]),
            isLoading: true
        });
        setCommandsLog(temp);
        setCommand('');
        setFile(null);
        scrollToBottom();
        api()
            .executeCommandFromConfigurator(serverUrl, command, token, globalFilterValue, file)
            .then(()=>{});
    }
    const loadChat = (page) => {
        api()
            .getLogsFromConfigurator(serverUrl, token, globalFilterValue, page)
            .then(logs => {
                let now = new Date();
                let logsForSave = logs.items.map(l=>{
                    let createAt = moment(new Date(new Date(l.createAt).getTime() - new Date().getTimezoneOffset() * 60000));
                    let isToDay = now.toLocaleDateString() === createAt.toDate().toLocaleDateString();
                    let timeOfTyping = l.text.split(' ').length * 100
                    let isTyping = (now - createAt) < timeOfTyping
                    return {
                        time: createAt.format((isToDay ? '' : 'DD:MM:yyyy ') + 'HH:mm'),
                        isTyping: isTyping,
                        fromUser: l.fromUser,
                        command: l.text,
                        isLoading: l.isLoading,
                        isError: l.isError,
                        files: l.files,
                    };});
                setCommandsLog(logsForSave)
                if(logs.totalCount !== totalCommandsLogLenght){
                    scrollToBottom();
                    setTotalCommandsLogLenght(logs.totalCount);
                }
            })
    }

    useEffect(() => {
        createConnection(serverUrl, userId, connection=>{
            connection.on("NewMessageFromVirtualDeveloper", data => {
                loadChat(0)
            });
        })
        
        loadChat(0)
        //const interval = setInterval(() => loadChat(0), 5000);
        //return () => clearInterval(interval);
    }, []);
    const downloadFile = (id, name)=>{
        api()
            .createDownloadToken(serverUrl, token, id, name)
            .then(resp => {
                if (resp.token !== undefined && resp.token !== '') {
                    window.open(`${serverUrl}/api/file/downloadBy?token=${resp.token}`, '_blank');
                }
            })
    }
    const renderFiles = (files)=>{
        return files !== undefined && files !== null && files !== '' && files !== '[]' ?
                <div className={'files-container'}>{JSON.parse(files).map(x=>
                    <div className={'file'} onClick={()=>{downloadFile(x.id, x.name)}}><Icon name={'download'} />{x.name}</div>)}</div> : ''
    }
    
    return (<div>
        <div className={'wrapper-for-chat-body'} style={{height: `${height - (file == null ? 110 : (110 + 35))}px`}}>
            <Container>
                <div id={'virtual-developer-chat-body'} className={'content'}>
                    {commandsLog.length === 0 && isLoadingLogs ? <div className={'loading-chat-logs-container'}><Loader active inline size='medium' /></div> : ''}
                    {commandsLog.length === 0 ? <div className={'init-help-message'}>
                        Введите команду и я выполню её.
                        <br/>
                        <br/>
                        Посмотрите примеры команд в <a href={'https://help.report.ms/ai_commands'} target={'_blank'}>справочнике</a>.
                    </div> : ''}
                    {commandsLog.length > 0 ? commandsLog.map(commandLog => <div className={'message-container' + (commandLog.fromUser ? ' from-user' : '')}>
                        <div className={'threeangle'}><div className={'threeangle-inner'}></div></div>
                        <div className={'message'}>
                            <div className={'message-inner'}>
                                <div>{commandLog.fromUser ? <>{commandLog.command}</> : <Typewriter enable={commandLog.isTyping} text={commandLog.command} delay={100} />} <div className={'time'}>{commandLog.time}</div></div>
                                {!commandLog.fromUser && commandLog.isError ? <Icon name={'exclamation triangle'} size='small' color={'red'} /> : ''}
                                {!commandLog.fromUser ? <img className={'virtual-developer-in-message'} src={'https://static.report.ms/virtual_developer.svg'}/> : ''}
                                {renderFiles(commandLog.files)}
                            </div>
                        </div>
                        {commandLog.fromUser ? 
                            <div  className={'message fake'}><div>{commandLog.command}{renderFiles(commandLog.files)}</div> {!commandLog.fromUser && commandLog.isError ? <Icon name={'exclamation triangle'} size='small' color={'red'} /> : ''} {commandLog.fromUser && commandLog.isLoading ? <Loader active inline size='mini' /> : ''}</div>: ''}
                    </div>) : ''}
                    {commandsLog.filter(x=>x.isLoading).length > 0 ? <div className={'message-container'}>
                        <div className={'threeangle'}><div className={'threeangle-inner'}></div></div>
                        <div className={'message'}>
                            <div className={'message-inner'}>
                                <div>
                                    <div className={'circle-create-message'}></div>
                                    <div className={'circle-create-message'}></div>
                                    <div className={'circle-create-message'}></div>
                                </div>
                                <img className={'virtual-developer-in-message'} src={'https://static.report.ms/virtual_developer.svg'}/>
                            </div>
                        </div>
                    </div> : ''}
                </div>
            </Container>            
        </div>
        <div className={'footer'}>
            <Container>
                {file == null ? '' : <div className={'file-container'}><Icon name={'file'} color={'grey'}/>{file.name}<Icon name={'close'} onClick={()=>{setFile(null)}} /></div>}
                <Form>
                    <RecognitionInput
                        placeholder='Введите команду...'
                        value={command}
                        onChange={e=>{setCommand(e.target.value)}}
                        onKeyDown={e=>{
                            if (e.ctrlKey === false && e.key === 'Enter' && command.length > 0){
                                e.preventDefault();
                                e.stopPropagation();
                                sendCommand();
                                return false;
                            }
                            if(e.ctrlKey === true && e.key === 'Enter'){
                                setCommand(e.target.value + '\n')
                                return false
                            }
                        }}
                    />
                    {file == null ? <div style={{position: 'absolute',top: '20px', right: '0', cursor: 'pointer'}}>
                        <Icon name={'paperclip'} color={'grey'} onClick={() => fileInputRef.current.click()}/>
                        <input
                            type="file"
                            hidden
                            ref={fileInputRef}
                            onChange={(e) => { return api().uploadFile(e.target.files[0], `${serverUrl}/api/file/upload`).then((resp)=> {
                                setFile(resp);
                            })}}/>
                    </div> : ''}
                </Form>
                {commandsLog.filter(x=>x.isLoading).length > 0 ? <Button
                    className={'execute-btn'}
                    disabled
                    primary
                    size={'mini'}
                >Дождитесь выполнения предыдущей команды</Button> : <Button
                    className={'execute-btn'}
                    primary
                    size={'mini'}
                    disabled={command.length === 0}
                    onClick={sendCommand}
                >Выполнить</Button>}
            </Container>
        </div></div>);
}

export default FullScreenChat;
