import * as signalR from "@microsoft/signalr";

export default function createConnection(serverUrl, userId, handler = ()=>{}, reloadIfChangeAiServer = true) {
    let url = (serverUrl.indexOf('http://localhost:') === 0) ? `${serverUrl}/apphub` : `${serverUrl}/api/apphub`
    let connection = new signalR.HubConnectionBuilder()
        .withUrl(url)
        .build();
    
    handler(connection)

    if(reloadIfChangeAiServer)
        connection.on("ChangeStateAiServer", data => {
            window.location = window.location 
        });

    connection
        .start()
        .then(()=>{
            connection.invoke("JoinGroup", userId);
        });

}