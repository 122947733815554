import './FullScreenInConfigurator.css';
import AwaitScreen from "./AwaitScreen/AwaitScreen";
import {useEffect, useState} from "react";
import {Loader} from "semantic-ui-react";
import FullScreenChat from "./FullScreenChat/FullScreenChat";
import api from "../utils/api";
import createConnection from "../utils/webSocket";
import moment from "moment/moment";

function FullScreenInConfigurator() {
    const [awaitOrChat, setAwaitOrChat] = useState(null)
    const [aiServerIsAvailable, setAiServerIsAvailable] = useState(null)
    const urlParams = new URLSearchParams(window.location.search);
    const serverUrl = urlParams.get('serverUrl');
    const userId = urlParams.get('userId');
    const token = urlParams.get('token');
    const globalFilterValue = urlParams.get('globalFilterValue');
    
    useEffect(()=>{
        if (globalFilterValue == 'null'){
            setAwaitOrChat('emptyProject')
            return
        }
        createConnection(serverUrl, userId)
        api()
            .getAppState(globalFilterValue, serverUrl, token)
            .then(appState => {
                setAiServerIsAvailable(appState.aiServerIsAvailable)
                setAwaitOrChat(appState.isCreating ? 'await' : 'chat')
            })
    }, [])
    moment().locale('ru');
    if(awaitOrChat === 'emptyProject')
        return <div className={'create-project-message'}>Создайте проект</div>
    
    if(awaitOrChat == null || aiServerIsAvailable == null)
        return <Loader active size={"big"}/>
    
    // if(!aiServerIsAvailable)
    //     return <div className={'create-project-message'}>
    //         <p>Сервер искусственного интелекта сейчас недоступен.</p>
    //         <p>Вы можете редактировать проект вручную.</p>
    //     </div>
    
    if(awaitOrChat === 'await')
        return <AwaitScreen serverUrl={serverUrl} token={token} globalFilterValue={globalFilterValue} userId={userId}/>
    
    return <FullScreenChat
        aiServerIsAvailable={aiServerIsAvailable}
        serverUrl={serverUrl} 
        token={token} 
        globalFilterValue={globalFilterValue} 
        userId={userId}
    />
}

export default FullScreenInConfigurator;
