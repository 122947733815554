import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import FullScreenInConfigurator from "./FullScreenChat/FullScreenInConfigurator";
import 'moment/locale/ru';

let elemDiv = document.createElement('div');
let haveServerUrlInQuery = window.location.href.indexOf('serverUrl') !== -1;
let elementId = haveServerUrlInQuery ? 'root' : 'virtual_developer_plugin';
elemDiv.id = elementId;
document.body.appendChild(elemDiv);
const root = ReactDOM.createRoot(document.getElementById(elementId));
root.render(haveServerUrlInQuery ? <FullScreenInConfigurator /> : <App />);