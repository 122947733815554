export default function api() {

    let createReqFile = (url, file) => {
        let config = {
            method: 'Post',
            headers: {
            }
        }

        if (localStorage.Identity !== undefined) {
            let token = JSON.parse(localStorage.Identity).accessToken || null;
            if (token)
                config.headers["Authorization"] = 'Bearer ' + token;
            else {
                throw "No token saved!";
            }
        }
        const formData = new FormData();
        formData.append("file", file);

        config.body = formData;

        try{
            return fetch((localStorage.getItem('backendUrl') ?? '') + url, config)
                .then(function (response) {
                        return response.text().then(function (text) {
                            return ({text: text, response: response});
                        })
                    }
                ).then(function (a) {
                    if (a.response.status === 401) {
                        localStorage.clear()
                        window.location = '/login';
                    }
                    if (!a.response.ok) {
                        return Promise.reject(a.text);
                    }
                    return a.text;
                })
                .then(function (resp) {
                    return JSON.parse(resp)
                }).catch(function (err) {
                    return ({errorMessage: err.message});
                });
        }catch (e) {
            return new Promise(()=>{ return ({errorMessage: e.message}); });
        }
    }
    
    let createReq = (method, url, data, token) => {
        let config = {
            method: method,
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            }
        }

        if (token !== undefined) {
            config.headers["Authorization"] = 'Bearer ' + token;
        }

        if ((method === 'Post' || method === 'Put') &&  data)
            config.body = JSON.stringify(data);
        
        try{
            return fetch(url, config)
                .then(function (response) {
                        if (response.ok) {
                            return response.text().then(function (text) {
                                return ({text: text, response: response});
                            });
                        } else {
                            throw new Error('Fetch error');
                        }
                    }
                ).then(function (a) {
                    if (a.response.status === 401) {
                        localStorage.clear()
                        window.location = '/login';
                    }
                    if (!a.response.ok) {
                        return Promise.reject(a.text);
                    }
                    return a.text;
                })
                .then(function (resp) {
                    return JSON.parse(resp)
                }).catch(function (err) {
                    return ({errorMessage: err.message});
                });            
        }catch (e) {
            return new Promise(()=>{ return ({errorMessage: e.message}); });
        }

    };
    
    return {
        executeCommand: (path, command, token, projectId, file) => {
            return createReq('Post', '/api/virtualDeveloperPlugin/execute', {
                configuratorToken: token,
                projectId: projectId,
                command: command,
                path: path,
                file: file != null ? JSON.stringify(file) : null
            });
        },
        executeCommandFromConfigurator: (serverUrl, command, token, projectId, file) => {
            return createReq('Post', serverUrl + '/api/virtualProgrammer/execute', {
                configuratorToken: token,
                projectId: projectId,
                command: command,
                path: '',
                file: file != null ? JSON.stringify(file) : null
            });
        },
        getLogs: (token, projectId, page) => {return createReq('Get', '/api/virtualDeveloperPlugin/logs?configuratorToken=' + token + '&projectId=' + projectId + '&page=' + page);},
        getLogsFromConfigurator: (serverUrl, token, projectId, page) => {return createReq('Get', serverUrl + '/api/virtualProgrammer/getLogs?token=' + token + '&projectId=' + projectId + '&page=' + page);},
        loadExamples: (path, token, projectId) => {return createReq('Get', '/api/virtualDeveloperPlugin/examples?path=' + path + '&configuratorToken=' + token + '&projectId=' + projectId);},
        uploadFile: (file, path) => { return createReqFile(path ?? '/api/file/upload', file);},
        getAppState: (appId, serverUrl, token) => { return createReq('Get',`${serverUrl}/api/getAppState?appId=${appId}&configuratorToken=${token}`);},
        createDownloadToken: (serverUrl, token, fileId, name) => { return createReq('Get', `${serverUrl}/api/file/createDownloadToken?fileId=${fileId}&name=${name}`, null, token);},
    };
}