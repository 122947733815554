import './App.css';
import {Button, Form, Icon, Loader, Popup} from "semantic-ui-react";
import {useEffect, useRef, useState} from "react";
import * as signalR from "@microsoft/signalr";
import Draggable from 'react-draggable';
import api from "./utils/api";
import RecognitionInput from "./components/RecognitionInput/RecognitionInput";
import {get20Symbols, getElementsFromFormattedText, uuidv4} from "./utils/textHelper";
import moment from "moment";

function App() {
    let [isEnabled, setIsEnabled] = useState(false);
    let [token, setToken] = useState(localStorage.getItem('configuratorToken') ?? null);
    let [projectId, setProjectId] = useState(localStorage.getItem('configuratorProjectId') ?? null);
    let [isCollapsed, setIsCollapsedInner] = useState((localStorage.getItem('virtual-developer-collapsed') ?? 'true') === 'true');
    let [command, setCommand] = useState('');
    let [commandsLog, setCommandsLog] = useState(JSON.parse(localStorage.getItem('virtual-developer-commands-log') ?? '[]'));
    let [totalCommandsLogLenght, setTotalCommandsLogLenght] = useState(parseInt(localStorage.getItem('virtual-developer-commands-log-total-lenght') ?? '0'));
    let [isLoadingLogs, setIsLoadingLogs] = useState(false);
    let [isDraggabbing, setIsDraggabbing] = useState(false);
    let [positionX, setPositionX] = useState(parseInt(localStorage.getItem('virtual-developer-x') ?? '40'));
    let [positionY, setPositionY] = useState(parseInt(localStorage.getItem('virtual-developer-y') ?? '40'));
    let [commandExamples, setCommandExamples] = useState([]);
    let [loadingExamples, setLoadingExamples] = useState(false);
    let [helpIsOpen, setHelpIsOpen] = useState(false);
    let [file, setFile] = useState(null);
    let fileInputRef = useRef();
    
    useEffect(()=>{
        if(token != null && projectId != null) {
            setIsEnabled(true);
            return;
        }
        
        let isLocalhost = window.location.origin.indexOf('http://localhost') === 0;
        let url = isLocalhost ? (window.location.origin + "/apphub") : `${window.location.protocol}//${window.configuration.info.location}/api/apphub`;
        let requestId = uuidv4();
        let connection = new signalR.HubConnectionBuilder()
            .withUrl(url)
            .build();

        connection.on("TokenForVirtualProgrammer", data => {
            setToken(data.token);
            localStorage.setItem('configuratorToken', data.token);
            setProjectId(data.projectId);
            localStorage.setItem('configuratorProjectId', data.projectId);
            setIsEnabled(true);
        });

        connection
            .start()
            .then(()=>{
                connection.invoke("JoinGroup", requestId)
                    .then(()=>{
                        debugger
                        const iframe = document.createElement('iframe');
                        let configuratorHost = isLocalhost ? 'http://localhost:5000' : 'https://configurator.report.ms';
                        iframe.src = configuratorHost + '/api/virtualProgrammer/canGetTokenForProject?requestId=' + requestId + '&project=' + window.configuration.info.location;
                        iframe.style = 'display: none;';
                        document.getElementsByTagName('body')[0].appendChild(iframe);
                        console.log(iframe)
                        console.log(configuratorHost)
                        console.log(iframe.src)
                    });
                
            });
    }, []);

    useEffect(() => {
        const interval = setInterval(() => loadLogs(0), 5000);

        return () => clearInterval(interval);
    }, []);
    
    let loadLogs = (page)=>{
        let token = localStorage.getItem('configuratorToken') ?? null;
        let projectId = localStorage.getItem('configuratorProjectId') ?? null;
        if(token == null || projectId == null)
            return;
        setIsLoadingLogs(true);
        api()
            .getLogs(token, projectId, page)
            .then(logs=>{
                setIsLoadingLogs(false);
                let now = new Date();
                let logsForSave = logs.items.map(l=>{
                    let createAt = moment(new Date(new Date(l.createAt).getTime() - new Date().getTimezoneOffset() * 60000));
                    let isToDay = now.toLocaleDateString() === createAt.toDate().toLocaleDateString();
                    return {
                        time: createAt.format((isToDay ? '' : 'DD:MM:yyyy ') + 'HH:mm'),
                        fromUser: l.fromUser,
                        command: l.text,
                        isLoading: l.isLoading,
                        isError: l.isError,
                    };});
                setCommandsLog(logsForSave);
                if(page === 0)
                    localStorage.setItem('virtual-developer-commands-log', JSON.stringify(logsForSave));
                if(logs.totalCount !== totalCommandsLogLenght){
                    scrollToBottom();
                    localStorage.setItem('virtual-developer-commands-log-total-lenght', logs.totalCount.toString());
                    setTotalCommandsLogLenght(logs.totalCount);
                }
                
            })
            .catch(error=>{
                setIsLoadingLogs(false);
                console.log(error);
                localStorage.removeItem('configuratorToken');
                localStorage.removeItem('configuratorProjectId');
                setIsEnabled(false);
            });
    };
    
    useEffect(()=>{
        if(isEnabled){
            loadLogs(0);
            if(commandsLog.length > 0)
                scrollToBottom();
        }
    }, [isEnabled]);
    
    let onOpenHelp = () => {
        setLoadingExamples(true);
        setCommandExamples([]);
        setHelpIsOpen(true);
        api()
            .loadExamples(window.location.pathname, token, projectId)
            .then(resp => {
                setLoadingExamples(false);
                setCommandExamples(resp);
            });        
    };
    
    let onCloseHelp = () => {
        setHelpIsOpen(false);        
    };
    
    let setIsCollapsed = (val) => {
        setIsCollapsedInner(val);
        localStorage.setItem('virtual-developer-collapsed', val ? 'true' : 'false');
        if(!val)
            scrollToBottom();
    };

    let scrollToBottom = () => {
        setTimeout(()=>{
            let chatBody = document.getElementById('virtual-developer-chat-body');
            if(chatBody)
                chatBody.scrollTop = chatBody.scrollHeight + 1000;
        }, 100);
    }
    
    let sendCommand = ()=>{
        if(commandsLog.filter(x=>x.isLoading).length > 0)
            return;
        let temp = commandsLog.map(x=>x);
        temp.push({
            time: new Date(),
            fromUser: true,
            command: command,
            isLoading: true
        });
        setCommandsLog(temp);
        setCommand('');
        scrollToBottom();
        api()
            .executeCommand(window.location.pathname, command, token, projectId, file)
            .then(()=>{});
    };
    
    if(!isEnabled)
        return <></>;
    
    if(isCollapsed)
        return <Popup trigger={<div className='collapsed-app' style={{top: positionY + 'px', left: positionX + 'px'}}><img
            className={'virtual-programmer-image'}
            src={'https://static.report.ms/virtual_developer.svg'}
            onClick={()=>{setIsCollapsed(!isCollapsed)}}
        /></div>} 
                      content={'Виртуальный программист выполняет ваши команды и изменяет приложение. Вы видите этот значок, потому что вам доступен конфигуратор. Обычные пользователи приложения его не видят.'} 
                      inverted 
                      basic />;

    return (<Draggable
        key={'draggable-virtual-developer-plugin'}
        bounds="parent"
        defaultPosition={{x: positionX, y: positionY}}
        onStart={()=>{
            setIsDraggabbing(true);
        }}
        onStop={(e, data)=>{
            //debugger;
            console.log('x: ' + data.x + ', y: ' + data.y);
            setPositionX(data.x);
            setPositionY(data.y);
            localStorage.setItem('virtual-developer-x', data.x.toString());
            localStorage.setItem('virtual-developer-y', data.y.toString());
            
            setIsDraggabbing(false);
        }}
        handle={'#virtual_developer_plugin .virtual-programmer-draggable-element'}>
        <div className={'App' + (command.length > 0 ? ' with-execute-btn' : '') + (file == null ? '' : ' with-file')}>
            {isDraggabbing? <style>
                {'#virtual_developer_plugin {top: 0px; left:0px; right: 0px; bottom:0px}'}
            </style> :''}
        <div className={'header'}>
            <Popup trigger={<img
                className={'virtual-programmer-image'}
                src={'https://static.report.ms/virtual_developer.svg'}
                onClick={()=>{setIsCollapsed(!isCollapsed)}}
            />}
                   content={'Свернуть'}
                   inverted
                   basic />
            <span className={'virtual-programmer-draggable-element' + (isDraggabbing ? ' draggabbing' : '')}>Виртуальный программист</span>
            <Popup trigger={<Icon name={'question circle outline'} size={'large'} className={'help-icon'}/>}
                   position={"bottom right"}
                   onOpen={onOpenHelp}
                   open={helpIsOpen}
                   onClose={onCloseHelp}
                   content={<div className={'virtual-developer-plugin-help-popup-content'}>
                       <div>
                           <p>Виртуальный программист изменяет проект на основе ваших команд.</p>
                           <p>Вот примеры команд:</p>
                           <div className={'help-examples'}>
                               {loadingExamples ? <div className={'loading-help-examples-container'}><Loader active inline size='medium' /></div> : ''}
                               {commandExamples.map(commandExample => <span className={'help-example-item'} onClick={()=>{
                                   setCommand(commandExample);
                                   setHelpIsOpen(false);
                               }}>{commandExample}</span>)}
                           </div>
                       </div>
                       <div>
                           <a href={'https://configurator.report.ms'} target={'_blank'} className={'help-link'}>Открыть конфигуратор <Icon name={"external alternate"} size={"small"}/></a>
                       </div>
                       <div>
                           <a href={'https://help.report.ms/ai_commands'} target={'_blank'} className={'help-link'}>Все доступные команды <Icon name={"external alternate"} size={"small"}/></a>
                       </div>
                       <div className={'ok-btn-container'}>
                           <Button size={"mini"} compact basic onClick={()=>{
                               setHelpIsOpen(false);
                           }}>Понятно</Button>
                       </div>
                   </div>}
                   on='click' />
        </div>
        <div id={'virtual-developer-chat-body'} className={'content'}>
            {/*{commandsLog.length === 0 && isLoadingLogs ? <div className={'loading-chat-logs-container'}><Loader active inline size='medium' /></div> : ''}*/}
            {commandsLog.length === 0 ? <div className={'init-help-message'}>
                Введите команду и я выполню её.
                <br/>
                <br/>
                Посмотрите примеры команд в <a href={'https://help.report.ms/ai_commands'} target={'_blank'}>справочнике</a>.
            </div> : ''}
            {commandsLog.length > 0 ? commandsLog.map(commandLog => <div className={'message-container' + (commandLog.fromUser ? ' from-user' : '')}>
                <div className={'threeangle'}><div className={'threeangle-inner'}></div></div>
                <div className={'message'}><Popup basic inverted position={'left center'} content={<>{commandLog.time}</>} trigger={<div>{commandLog.fromUser ? commandLog.command : getElementsFromFormattedText(commandLog.command)}</div>} /> {!commandLog.fromUser && commandLog.isError ? <Icon name={'exclamation triangle'} size='small' color={'red'} /> : ''} {commandLog.fromUser && commandLog.isLoading ? <Loader active inline size='mini' /> : ''}</div>
                {commandLog.fromUser ? <div  className={'message fake'}><Popup basic inverted position={'left center'} content={<>{commandLog.time}</>} trigger={<div>{commandLog.command}</div>} /> {!commandLog.fromUser && commandLog.isError ? <Icon name={'exclamation triangle'} size='small' color={'red'} /> : ''} {commandLog.fromUser && commandLog.isLoading ? <Loader active inline size='mini' /> : ''}</div>: ''}
            </div>) : ''}
        </div>  
        <div className={'footer'}>
            <Form>
                <RecognitionInput 
                    placeholder='Введите команду...' 
                    value={command} 
                    onChange={e=>{setCommand(e.target.value)}}
                    onKeyDown={e=>{
                        if (e.ctrlKey === false && e.key === 'Enter' && command.length > 0){
                            e.preventDefault();
                            e.stopPropagation();
                            sendCommand();
                            return false;
                        }
                    }}
                />
                {file == null ? <div style={{position: 'absolute',top: '20px', right: '0', cursor: 'pointer'}}>
                    <Icon name={'paperclip'} color={'grey'} onClick={() => fileInputRef.current.click()}/>
                    <input
                        type="file"
                        hidden
                        ref={fileInputRef}
                        onChange={(e) => { return api().uploadFile(e.target.files[0]).then((resp)=> {
                            setFile(resp);
                        })}}/>
                </div> : ''}
            </Form>
            {file == null ? '' : <div className={'file-container'}><Icon name={'file'} color={'grey'}/>{get20Symbols(file.name)}<Icon name={'close'} onClick={()=>{setFile(null)}} /></div>}
            {command.length > 0 ? (commandsLog.filter(x=>x.isLoading).length > 0 ? <Popup trigger={<Button
                className={'execute-btn'}
                disabled
                primary
                size={'mini'}
            >Выполнить</Button>} inverted basic content={'Дождитесь выполнения предыдущей команды'}/> : <Button
                className={'execute-btn'}
                primary
                size={'mini'}
                onClick={sendCommand}
            >Выполнить</Button>) : ''}
        </div>
    </div></Draggable>);
}

export default App;
