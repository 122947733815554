import './AwaitScreen.css';
import {
    Button,
    Container,
    Form,
    Grid,
    GridColumn,
    Header,
    Icon,
    Loader,
    Message, MessageHeader,
    Popup,
    Progress
} from "semantic-ui-react";
import {useEffect, useRef, useState} from "react";
import Draggable from 'react-draggable';
import api from "../../utils/api";
import moment from "moment";
import createConnection from "../../utils/webSocket";

function AwaitScreen({serverUrl, token, globalFilterValue, userId}) {
    const messagesForView = 8
    const [percent, setPercent] = useState(10);
    const [left, setLeft] = useState(800);
    const [messages, setMessages] = useState([]);
    const [stages, setStages] = useState([]);
    
    const loadState = () => {
        api()
            .getAppState(globalFilterValue, serverUrl, token)
            .then(appState => {
                if(!appState.isCreating){
                    window.location = window.location;
                    return
                }
                setPercent(appState.percent)
                setStages(appState.stages)
                setLeft(appState.left)
            })
    }
    useEffect(() => {
        createConnection(serverUrl, userId, connection=>{
            connection.on("ViewToastFromVirtualDeveloper", data => {
                setMessages(x=>{
                    let temp = x.map(z=>z)
                    return [{message: data, time: new Date()}, ...temp]
                })
            });
        })
        
        loadState()
        const updatePercentTimer = setInterval(() => {
            loadState()
        }, 5000);

        const updateTimers = setInterval(() => {
            setLeft(x => x < 1 ? 0 : x - 1)
            setStages(stages=>{
                let temp = stages.map(x=>x)
                let running = temp.filter(x=>x.isRunning)[0]
                if(running != undefined)
                    running.duration = running.duration + 1
                return temp
            })
        }, 1000);

        return () => {clearInterval(updatePercentTimer);clearInterval(updateTimers);}
    }, []);
    
    return (<Container className={'await-screen-creating'}>
        <Header as='h1'>Виртуальный программист создаёт проект по вашему описанию</Header>
        <Progress percent={percent} indicating progress active label={left == 0 ? 'Осталось совсем немного...' : `Осталось ~ ${moment.duration(left,'seconds').humanize()}`} size='big' />
        <Grid>
            <GridColumn width={6} style={{paddingTop: '55px'}}>
                {stages.map(x=>
                    <div style={{marginBottom: '25px'}}>
                        {x.isFinish ? <Icon size={'large'} name={'check circle outline'}/> : ''}
                        {x.isRunning ? <Loader active inline size='small' style={{marginRight: '5px'}} /> : ''}
                        {!x.isRunning && !x.isFinish ? <Icon size={'large'} name={'circle outline'}/> : ''}
                        <span style={{fontSize: 'large', verticalAlign: 'middle', fontWeight: x.isFinish ? 'bold' : ''}}>{x.name}</span>
                        {x.isFinish || x.isRunning ? <span style={{verticalAlign: 'bottom', color: 'gray', marginLeft: '15px'}}> {x.duration < 60*3 ? `${x.duration} сек.`: moment.duration(x.duration,'seconds').humanize()}</span> : ''}
                    </div>
                )}
            </GridColumn>
            <GridColumn width={4}><img style={{width: '100%'}} src={'https://static.report.ms/virtual_developer.svg'}/></GridColumn>
            <GridColumn width={6} style={{overflow: 'hidden',height: '420px', position: 'relative'}}>{
                messages.map(message => <div 
                    key={'message-key-' + message.message + message.time} 
                    className={'message-from-developer'}>
                    {message.message} <span className={'time'}>{moment(message.time).format('HH:mm')}</span></div>)
            }
            <div style={{position: 'absolute', width: '100%', height: '420px', background: 'linear-gradient(rgb(230, 100, 101, 0), rgb(255, 255, 255))', top: '0px'}}/></GridColumn>
        </Grid>
        <Message>
            <MessageHeader>По завершении генерации проекта:</MessageHeader>
            <p>
                <ul>
                    <li>Вам будет доступна его модификация</li>
                    <li>Вы получите письмо о данных для входа в проект</li>
                </ul>
            </p>
        </Message>
    </Container>);
}

export default AwaitScreen;
